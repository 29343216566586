<template>
  <v-main v-if="lvl == 1">
    <v-card>
      <v-card-title>
        구매인증
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        flat
        id="purchase_info_table"
        no-data-text="목록이 없습니다."
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          itemsPerPageText: '페이지 표시'
        }"
      >
      <!-- show screenshot dialog -->
      <template v-slot:top >
        <v-dialog v-model="Img_dialog" max-width="500px" >
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>
              스크린샷
              <input type="text" id="readonly_orderId" v-model="img_orderid" disabled />
            </v-card-title>
            <v-card-text class="mt-3">
              <v-img :aspect-ratio="1.35" :src="img_src" class="grey lighten-2" contain>
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="Img_dialog = false"> ok </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:item.admin_check="{item}">
        <v-btn v-if="item.admin_check==0" text icon color="pink" @click="confirm_info($event, item)">
          <v-icon>mdi-heart</v-icon>
          확인
        </v-btn>
        <v-btn v-if="item.admin_check==1" text icon color="deep-grey" @click="confirm_undo($event, item)">
          <v-icon>mdi-heart</v-icon>
          확인됨
        </v-btn>
      </template>
      <template v-slot:item.screenshot="{item}">
        <v-btn v-if="item.screenshot_link1" text small color="primary" class="ma-1" grey @click="showImg($event, item, 1)">보기</v-btn>
        <v-btn v-if="item.screenshot_link2" text small color="primary" class="ma-0 pa-0" grey @click="showImg($event, item, 2)">보기</v-btn>
      </template>

      </v-data-table>
    </v-card>
  </v-main>
</template>


<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex'


export default {
  props: {
    msg: String
  },
  data () {
    return {
      Img_dialog: false,
      img_src: '',
      img_orderid: '',
      search: '',
      headers: [
        {
          text: '관리자 확인',
          value: 'admin_check',
        },
        { text: '주문ID', value: 'orderId' },
        { text: '주문샵', value: 'orderShop' },
        { text: '스크린샷', value: 'screenshot' },
        { text: '회원ID', value: 'email' },
        { text: '날짜', value: 'date' },
      ],
      desserts: [],
      selectedItem: [],
      selectedIndex: -1,
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      lvl: "lvl",

    })
  },

  mounted: async function() {
    if ( firebase.auth().currentUser ) {
      // 이전 정보가 있는지 확인
      const id_token = await firebase.auth().currentUser.getIdToken();
      const user = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/admin/user/list', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      })
      this.arrangement()
    }
  },
  methods: {
    Img_dialog_close() {
      this.Img_dialog = false
    },
    async arrangement () {
      const id_token = await firebase.auth().currentUser.getIdToken();
      const purchaseinfo = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/purchaseinfo/all', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });
      if( purchaseinfo.data.length > 0 ) {
        this.desserts = purchaseinfo.data
      }
    },
    showImg(event, item, link_num) {
      this.selectedIndex = this.desserts.indexOf(item)
      this.selectedItem = Object.assign({}, item)
      this.img_orderid = this.selectedItem.orderId
      if ( link_num === 1 ) {
        this.img_src = this.selectedItem.screenshot_link1
      } else if ( link_num === 2 ) {
        this.img_src = this.selectedItem.screenshot_link2
      }
      this.Img_dialog = true
    },
    async confirm_undo(event, item){
      item.admin_check = 0
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/purchaseinfo', {
        email: this.user.data.email,
        orderId_old: item.orderId,
        info: item
      }, {
        headers: {'id_token': id_token},
      })
      this.arrangement()
    },
    async confirm_info(event, item) {
      item.admin_check = 1
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/purchaseinfo', {
        email: this.user.data.email,
        orderId_old: item.orderId,
        info: item
      }, {
        headers: {'id_token': id_token},
      })
      this.arrangement()
    },
  }
}
</script>

<style scoped>
  #readonly_orderId {
    text-align: right;
  }
</style>
